import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL } from './config';
import { Moon, Sun, Send, LogIn, Loader, Trash2, Sparkles, Menu, X, Star } from 'lucide-react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import confetti from 'canvas-confetti';
import backgroundImage from './assets/backtop.jpeg';
import habibi02 from './assets/habibi02.jpeg';

const App = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [favoriteChats, setFavoriteChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const messagesEndRef = useRef(null);
  const sidebarRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    if (storedDarkMode) {
      setDarkMode(JSON.parse(storedDarkMode));
    }
    const storedMessages = localStorage.getItem('chatMessages');
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
    const storedFavorites = localStorage.getItem('favoriteChats');
    if (storedFavorites) {
      setFavoriteChats(JSON.parse(storedFavorites));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    localStorage.setItem('favoriteChats', JSON.stringify(favoriteChats));
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [messages, darkMode, favoriteChats]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    let touchStartX = 0;
    let touchEndX = 0;

    const handleTouchStart = (e) => {
      touchStartX = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
      touchEndX = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
      if (touchStartX - touchEndX > 75) {
        // Swipe left
        setIsSidebarOpen(false);
      } else if (touchEndX - touchStartX > 75) {
        // Swipe right
        setIsSidebarOpen(true);
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || isLoading) return;

    const userMessage = { text: input, user: true, timestamp: new Date().toISOString() };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsLoading(true);
    setError('');
    setInput('');

    try {
      await new Promise(resolve => setTimeout(resolve, 500)); // 1-second delay
      const response = await axios.post(`${API_URL}/api/chat`, { message: input });
      if (response.data && response.data.reply) {
        const botMessage = { text: response.data.reply, user: false, timestamp: new Date().toISOString() };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
        triggerConfetti();
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(`Се случи грешка: ${error.response?.data?.error || error.message}. Ве молиме обидете се повторно подоцна.`);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(prevDarkMode => !prevDarkMode);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    triggerConfetti();
  };

  const clearChatHistory = () => {
    setMessages([]);
    localStorage.removeItem('chatMessages');
  };

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const toggleFavorite = (chat, e) => {
    e.stopPropagation();
    setFavoriteChats((prevFavorites) => {
      if (prevFavorites.includes(chat)) {
        return prevFavorites.filter((fav) => fav !== chat);
      } else {
        return [...prevFavorites, chat];
      }
    });
  };

  const selectChat = (chat) => {
    setCurrentChat(chat);
    // Here you would typically load the messages for this chat
    // For now, we'll just set a placeholder message
    setMessages([{ text: `You've selected the chat: ${chat}`, user: false, timestamp: new Date().toISOString() }]);
    if (window.innerWidth < 768) {  // Close sidebar on mobile after selecting a chat
      setTimeout(() => setIsSidebarOpen(false), 300); // Delay closing sidebar
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="min-h-screen bg-gray-900 text-white flex items-center">
        <div className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-8xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">ХАБИБИ</h1>
            <div className="max-w-md">
              <p className="text-2xl mb-4 text-gray-300">Зајакнете ја вашата креативност и продуктивност</p>
              <p className="mb-8 text-lg text-gray-400">Разговарајте за да започнете со пишување, планирање, учење и повеќе со ХАБИБИ AI</p>
            </div>
            <button
              onClick={handleLogin}
              className="bg-blue-500 text-white font-semibold py-3 px-8 rounded-full hover:bg-blue-600 transition duration-300 flex items-center"
            >
              Започнете разговор со ХАБИБИ
            </button>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0">
            <img src={habibi02} alt="ХАБИБИ AI" className="rounded-2xl shadow-2xl" />
          </div>
        </div>
        <div className="absolute bottom-4 right-4 text-white text-lg font-semibold">
          Hello MICI &lt;3
        </div>
      </div>
    );
  }

  return (
    <div className={`flex h-screen ${darkMode ? 'dark' : ''}`}>
      {/* Sidebar */}
      <motion.div
        ref={sidebarRef}
        initial={false}
        animate={{
          x: isSidebarOpen ? 0 : "-100%",
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className={`fixed inset-y-0 left-0 z-40 w-64 bg-gray-900 text-white border-r border-gray-700 flex flex-col ${isSidebarOpen ? '' : 'md:translate-x-0'}`}
      >
        <div className="p-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold">ХАБИБИ</h1>
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="p-2 rounded-full hover:bg-white/20 text-white md:hidden"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="flex-1 overflow-y-auto">
          <button
            onClick={() => selectChat('New Chat')}
            className="w-full text-left py-2 px-4 hover:bg-gray-800 transition duration-150 ease-in-out flex items-center"
          >
            <span className="mr-2">+</span> Започни нов разговор
          </button>
          <div className="mt-4">
            <h2 className="px-4 py-2 text-sm font-semibold text-gray-400 uppercase tracking-wider">
              Омилени
            </h2>
            {favoriteChats.length > 0 ? (
              favoriteChats.map((chat, index) => (
                <button
                  key={index}
                  className="w-full text-left py-2 px-4 hover:bg-gray-800 transition duration-150 ease-in-out flex items-center justify-between"
                  onClick={() => selectChat(chat)}
                >
                  <span>{chat}</span>
                  <Star className="w-5 h-5 text-yellow-500 fill-current" onClick={(e) => toggleFavorite(chat, e)} />
                </button>
              ))
            ) : (
              <p className="px-4 py-2 text-sm text-gray-500">
                Означете ги разговорите што често ги користите
              </p>
            )}
          </div>
          <div className="mt-4">
            <h2 className="px-4 py-2 text-sm font-semibold text-gray-400 uppercase tracking-wider">
              Неодамнешни
            </h2>
            {['AI Почетоци', 'Македонска историја', 'Совети за готвење', 'Патување во Токио'].map((chat, index) => (
              <button
                key={index}
                className="w-full text-left py-2 px-4 hover:bg-gray-800 transition duration-150 ease-in-out flex items-center justify-between"
                onClick={() => selectChat(chat)}
              >
                <span>{chat}</span>
                <Star
                  className={`w-5 h-5 ${favoriteChats.includes(chat) ? 'text-yellow-500 fill-current' : 'text-gray-400'}`}
                  onClick={(e) => toggleFavorite(chat, e)}
                />
              </button>
            ))}
          </div>
        </div>
        <div className="p-4 border-t border-gray-700">
          <button
            onClick={() => {/* Implement account settings logic */}}
            className="w-full text-left py-2 px-4 hover:bg-gray-800 transition duration-150 ease-in-out flex items-center"
          >
            <div className="w-8 h-8 rounded-full bg-purple-500 flex items-center justify-center text-white font-bold mr-2">
              M
            </div>
            Mici@yahoo.com
          </button>
        </div>
      </motion.div>

      {/* Main chat area */}
      <div className={`flex-1 flex flex-col transition-all duration-300 ease-in-out ${isSidebarOpen ? 'md:ml-64' : ''}`}>
        <header className="bg-gradient-to-r from-purple-500 to-pink-500 dark:from-purple-900 dark:to-pink-900 shadow z-30 relative">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="mr-4 p-2 rounded-full hover:bg-white/20 text-white"
              >
                {isSidebarOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </button>
              <h1 className="text-2xl font-bold text-white">Господин ХАБИБИ</h1>
            </div>
            <div className="flex items-center space-x-4">
              <button onClick={clearChatHistory} className="p-2 rounded-full hover:bg-white/20 text-white">
                <Trash2 className="w-6 h-6" />
              </button>
              <button onClick={toggleDarkMode} className="p-2 rounded-full hover:bg-white/20 text-white">
                {darkMode ? <Sun className="w-6 h-6" /> : <Moon className="w-6 h-6" />}
              </button>
              <button onClick={() => setIsLoggedIn(false)} className="p-2 rounded-full hover:bg-white/20 text-white">
                <LogIn className="w-6 h-6" />
              </button>
            </div>
          </div>
        </header>

        <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-100 dark:bg-gray-900 relative">
          <div
            className="fixed inset-0 z-0 opacity-50 pointer-events-none"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
          <div className="relative z-10">
            <AnimatePresence>
              {messages.map((message, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className={`flex ${message.user ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-lg p-3 ${
                      message.user
                        ? 'bg-gradient-to-r from-purple-500 to-pink-500 text-white'
                        : darkMode
                        ? 'bg-gray-800 text-gray-200'
                        : 'bg-white text-gray-900'
                    }`}
                  >
                    <p>{message.text}</p>
                    <span className={`text-xs mt-1 block ${message.user ? 'text-gray-200' : darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {new Date(message.timestamp).toLocaleTimeString()}
                    </span>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
            {isLoading && (
              <div className="flex justify-center">
                <Loader className="w-6 h-6 text-purple-500 animate-spin" />
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-800 p-4 z-20 relative">
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Напишете ја вашата порака..."
              className="flex-1 border rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-purple-500 dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
            <button
              type="submit"
              disabled={isLoading}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-full p-2 hover:from-purple-600 hover:to-pink-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default App;